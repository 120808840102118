import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { VehiclePage } from '../pages/account-page/vehicle-page/VehiclePage';
import { SignUpPage } from '../pages/authentication-page/SignUpPage';
import { LandingPage } from '../pages/landing-page/LandingPage';
import { LocationPage } from '../pages/location-page/LocationPage';
import { LocationsPage } from '../pages/locations-page/LocationsPage';
import { PedestalPage } from '../pages/pedestal-page/PedestalPage';
import { WalletPage } from '../pages/wallet-page/WalletPage';
import { WalletAddFundsToLocationPage } from '../pages/wallet-page/wallet-add-funds-to-location-page/WalletAddFundsToLocationPage';
import { WalletFundsStatsPage } from '../pages/wallet-page/wallet-funds-stats-page/WalletFundsStatsPage';
import { PrivacyPolicyPage } from '../pages/privacy-policy-page/PrivacyPolicyPage';
import { ScanPage } from '../pages/scan-page/ScanPage';
import { SessionPage } from '../pages/session-page/SessionPage';
import { TermsOfSalePage } from '../pages/terms-of-sale-page/TermsOfSalePage';
import { TermsOfUsePage } from '../pages/terms-of-use-page/TermsOfUsePage';
import { useUser } from '../shared/providers/user-provider';
import { PrivateRoute } from './PrivateRoute';
import { PublicRoute } from './PublicRoute';
import { appRoutes } from './app-routes';
import { WalletAddFundsPage } from '../pages/wallet-page/wallet-add-funds-page/WalletAddFundsPage';
import { CheckEmailPage } from '../pages/check-email-page/CheckEmailPage';
import { ErrorPage } from '../pages/error-page/ErrorPage';
import { AccountPage } from '../pages/account-page/AccountPage';
import { HistoryPage } from '../pages/history-page/HistoryPage';
import { SuccessPage } from '../pages/successful-page/SuccessfulPage';
import { PersonalInformationPage } from '../pages/account-page/personal-information-page/PersonalInformationPage';
import { ChangeEmailPage } from '../pages/account-page/email-page/ChangeEmailPage';
import { VerifyEmailPage } from '../pages/account-page/email-page/VerifyEmailPage';
import { PasswordPage } from '../pages/account-page/password-page/PasswordPage';
import { ReceiptDetailsPage } from '../pages/history-page/history-overview-page/ReceiptDetailsPage';
import { PaymentErrorPage } from '../pages/error-page/PaymentErrorPage';
import { PaymentCancelPage } from '../pages/payment-cancel-page/PaymentCancelPage';
import { ForgotPasswordPage } from '../pages/forgot-password-page/ForgotPasswordPage';
import { ResetPasswordPage } from '../pages/reset-password-page/ResetPasswordPage';
import { UsernameSignInPage } from '../pages/sign-in-page/UsernameSignInPage';
import { EmailSignInPage } from '../pages/sign-in-page/EmailSignInPage';
import { ChangeUsernamePage } from '../pages/account-page/email-page/ChangeUsernamePage';
import { PersonnelPage } from '../pages/account-page/personnel-page/PersonnelPage';
import { CompanyInvoiceInformationPage } from '../pages/account-page/company-invoice-data-page/CompanyInvoiceInformationPage';
import { PaymentOrderDetailsPage } from '../pages/history-page/history-overview-page/PaymentOrderDetailsPage';

export const AppRouter = (): JSX.Element => {
  const user = useUser();
  const isAuthenticated = !!user;

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Navigate to={appRoutes.landing} />}></Route>
        <Route
          path={appRoutes.landing}
          element={
            <PublicRoute
              component={LandingPage}
              showFooter
              isAuthenticated={isAuthenticated}
              showBottomNavigationBar
            />
          }
        />
        <Route
          path={appRoutes.checkEmail}
          element={
            <PublicRoute
              component={CheckEmailPage}
              isAuthenticated={isAuthenticated}
            />
          }
        />
        <Route
          path={appRoutes.signUp}
          element={
            <PublicRoute
              component={SignUpPage}
              isAuthenticated={isAuthenticated}
            />
          }
        />
        <Route
          path={appRoutes.emailSignIn}
          element={
            <PublicRoute
              component={EmailSignInPage}
              isAuthenticated={isAuthenticated}
            />
          }
        />
        <Route
          path={appRoutes.usernameSignIn}
          element={
            <PublicRoute
              component={UsernameSignInPage}
              isAuthenticated={isAuthenticated}
            />
          }
        />
        <Route
          path={appRoutes.forgotPassword}
          element={
            <PublicRoute
              component={ForgotPasswordPage}
              isAuthenticated={isAuthenticated}
            />
          }
        />
        <Route
          path={`${appRoutes.resetPassword}/:passwordResetToken`}
          element={
            <PublicRoute
              component={ResetPasswordPage}
              isAuthenticated={isAuthenticated}
            />
          }
        />
        <Route
          path={appRoutes.scan}
          element={
            <PublicRoute
              component={ScanPage}
              showHeader={false}
              isAuthenticated={isAuthenticated}
            />
          }
        />
        <Route
          path={appRoutes.wallet}
          element={
            <PrivateRoute
              component={WalletPage}
              isAuthenticated={isAuthenticated}
              showBottomNavigationBar
            />
          }
        />
        <Route
          path={appRoutes.walletAddFunds}
          element={
            <PrivateRoute
              component={WalletAddFundsPage}
              isAuthenticated={isAuthenticated}
            />
          }
        />
        <Route
          path={`${appRoutes.walletAddFunds}/:locationId`}
          element={
            <PublicRoute
              component={WalletAddFundsToLocationPage}
              isAuthenticated={isAuthenticated}
            />
          }
        />
        <Route
          path={`${appRoutes.walletFundsStats}/:locationId`}
          element={
            <PublicRoute
              component={WalletFundsStatsPage}
              isAuthenticated={isAuthenticated}
              showBottomNavigationBar
            />
          }
        />
        <Route
          path={`${appRoutes.location}/:locationId`}
          element={
            <PublicRoute
              component={LocationPage}
              showHeader={false}
              isAuthenticated={isAuthenticated}
              showBottomNavigationBar
            />
          }
        />
        <Route
          path={appRoutes.locations}
          element={
            <PublicRoute
              component={LocationsPage}
              isAuthenticated={isAuthenticated}
            />
          }
        />
        <Route
          path={appRoutes.privacyPolicy}
          element={
            <PublicRoute
              component={PrivacyPolicyPage}
              isAuthenticated={isAuthenticated}
              showBottomNavigationBar
              showFooter
            />
          }
        />
        <Route
          path={appRoutes.termsOfUse}
          element={
            <PublicRoute
              component={TermsOfUsePage}
              isAuthenticated={isAuthenticated}
              showBottomNavigationBar
              showFooter
            />
          }
        />
        <Route
          path={appRoutes.termsOfSale}
          element={
            <PublicRoute
              component={TermsOfSalePage}
              isAuthenticated={isAuthenticated}
              showBottomNavigationBar
              showFooter
            />
          }
        />
        <Route
          path={appRoutes.success}
          element={
            <PrivateRoute
              component={SuccessPage}
              isAuthenticated={isAuthenticated}
            />
          }
        />
        <Route
          path={appRoutes.paymentError}
          element={
            <PrivateRoute
              component={PaymentErrorPage}
              isAuthenticated={isAuthenticated}
            />
          }
        />
        <Route
          path={appRoutes.paymentCancel}
          element={
            <PrivateRoute
              component={PaymentCancelPage}
              isAuthenticated={isAuthenticated}
            />
          }
        />
        <Route
          path={`${appRoutes.pedestal}/:pedestalId`}
          element={
            <PrivateRoute
              component={PedestalPage}
              isAuthenticated={isAuthenticated}
              showBottomNavigationBar
            />
          }
        />
        <Route
          path={`${appRoutes.session}/:sessionId`}
          element={
            <PrivateRoute
              component={SessionPage}
              isAuthenticated={isAuthenticated}
            />
          }
        />
        <Route
          path={appRoutes.account}
          element={
            <PrivateRoute
              component={AccountPage}
              isAuthenticated={isAuthenticated}
              showBottomNavigationBar
            />
          }
        />
        <Route
          path={appRoutes.vehicles}
          element={
            <PrivateRoute
              component={VehiclePage}
              isAuthenticated={isAuthenticated}
              showBottomNavigationBar
            />
          }
        />
        <Route
          path={appRoutes.history}
          element={
            <PrivateRoute
              component={HistoryPage}
              isAuthenticated={isAuthenticated}
              showBottomNavigationBar
            />
          }
        />
        <Route
          path={`${appRoutes.receipt}/:receiptId`}
          element={
            <PrivateRoute
              component={ReceiptDetailsPage}
              isAuthenticated={isAuthenticated}
              showBottomNavigationBar
            />
          }
        />
        <Route
          path={`${appRoutes.order}/:orderId`}
          element={
            <PrivateRoute
              component={PaymentOrderDetailsPage}
              isAuthenticated={isAuthenticated}
              showBottomNavigationBar
            />
          }
        />
        <Route
          path="*"
          element={
            <PublicRoute
              isAuthenticated={isAuthenticated}
              component={ErrorPage}
            />
          }
        />
        <Route
          path={appRoutes.personalInformation}
          element={
            <PrivateRoute
              component={PersonalInformationPage}
              isAuthenticated={isAuthenticated}
              showBottomNavigationBar
            />
          }
        />
        <Route
          path={appRoutes.personnelPage}
          element={
            <PrivateRoute
              component={PersonnelPage}
              isAuthenticated={isAuthenticated}
              showBottomNavigationBar
            />
          }
        />
        <Route
          path={appRoutes.changeEmailPage}
          element={
            <PrivateRoute
              component={ChangeEmailPage}
              isAuthenticated={isAuthenticated}
              showBottomNavigationBar
            />
          }
        />
        <Route
          path={appRoutes.changeUsernamePage}
          element={
            <PrivateRoute
              component={ChangeUsernamePage}
              isAuthenticated={isAuthenticated}
              showBottomNavigationBar
            />
          }
        />
        <Route
          path={appRoutes.companyInvoiceInformationPage}
          element={
            <PrivateRoute
              component={CompanyInvoiceInformationPage}
              isAuthenticated={isAuthenticated}
              showBottomNavigationBar
            />
          }
        />
        <Route
          path={appRoutes.verifyEmailPage}
          element={
            <PrivateRoute
              component={VerifyEmailPage}
              isAuthenticated={isAuthenticated}
              showBottomNavigationBar
            />
          }
        />
        <Route
          path={appRoutes.passwordPage}
          element={
            <PrivateRoute
              component={PasswordPage}
              isAuthenticated={isAuthenticated}
              showBottomNavigationBar
            />
          }
        />
      </Routes>
    </BrowserRouter>
  );
};
