import { css } from '@emotion/css';
import { button2Style, valueStyle } from '../../../shared/styles/text';
import { Vehicle } from '../../../shared/types/server-types';
import { CheckCircleSolid, PlusIcon } from '../../../shared/assets/icons';
import { Colors } from '../../../shared/styles/colors';
import { useNavigator } from '../../../routing/navigator';

interface IVehicleDropdownProps {
  dropdownVisible: boolean;
  setDropdownVisible(prop: boolean): void;
  vehicles: Vehicle[];
  selectedVehicle: Vehicle;
  setSelectedVehicle: any;
}

export const VehicleDropdown = (props: IVehicleDropdownProps): JSX.Element => {
  const {
    vehicles,
    setSelectedVehicle,
    selectedVehicle,
    setDropdownVisible,
    dropdownVisible,
  } = props;
  const navigator = useNavigator();

  return (
    <ul className={vehicleDropdownStyle(dropdownVisible)}>
      {vehicles.map((vehicle: Vehicle, index: number) => (
        <li
          onClick={() => {
            setSelectedVehicle(vehicle);
            setDropdownVisible(false);
          }}
          key={index}
        >
          <span>{vehicle.registration}</span>
          <CheckCircleSolid
            className={isVisibleStyle(selectedVehicle === vehicle)}
          />
        </li>
      ))}
      <li onClick={navigator.toVehicles}>
        <PlusIcon />
        <span>add a vehicle</span>
      </li>
    </ul>
  );
};

const vehicleDropdownStyle = (dropdownVisible: boolean) => css`
  display: ${dropdownVisible ? 'flex' : 'none'};
  position: absolute;
  bottom: 100%;
  left: 0px;
  width: 100%;
  height: 100%;
  flex-direction: column;
  list-style: none;
  -webkit-box-shadow: 0px -2px 40px 0px rgba(154, 159, 163, 0.25);
  box-shadow: 0px -2px 40px 0px rgba(154, 159, 163, 0.25);
  border-radius: 0.5rem;
  z-index: 4;
  li {
    ${valueStyle}
    display: flex;
    text-transform: uppercase;
    width: 100%;
    padding: 1rem 1rem 0.9375rem 1rem;
    background: white;
    justify-content: space-between;
    border-bottom: 1px solid ${Colors.LIGHT_GRAY};
    min-height: 3.5rem;
    span {
      align-self: center;
      display: inline-flex;
      align-items: center;
    }
    &:first-child {
      border-top-left-radius: 0.5rem;
      border-top-right-radius: 0.5rem;
      border-bottom: 1px solid ${Colors.LIGHT_GRAY};
    }
    &:last-child {
      ${button2Style}
      border-bottom-left-radius: 0.5rem;
      border-bottom-right-radius: 0.5rem;
      border-bottom: none;
      justify-content: left;
      color: ${Colors.CAPRI_BLUE};
      span {
        margin-left: 0.5rem;
      }
    }
  }
`;

const isVisibleStyle = (isVisible: boolean) => css`
  visibility: ${isVisible ? 'visible' : 'hidden'};
`;
