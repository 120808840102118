import { css } from '@emotion/css';
import { Layout } from '../../../shared/components/layout/Layout';
import { h1Style } from '../../../shared/styles/text';
import { useUser } from '../../../shared/providers/user-provider';
import { TextInput } from '../../../shared/components/TextInput';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  ButtonSizeEnum,
  ButtonVariantEnum,
} from '../../../shared/components/Button';
import { ChevronLeft } from '../../../shared/assets/icons';
import { useNavigator } from '../../../routing/navigator';
import { useUpdateMyCompanyInvoiceInformation } from '../../../shared/services/api/user';
import { UpdateMeParams } from '../../../shared/types/input-param-types';
import { useState } from 'react';
import { showPrivateToast } from '../../../shared/utils/show-private-toast';
import { SelectInput } from '../../../shared/components/SelectInput';
import { SupportedCountries } from '../../../shared/types/enums';

const validationSchema: yup.SchemaOf<UpdateMeParams> = yup
  .object()
  .shape({
    r1CompanyName: yup.string().required('Name is a required field.'),
    r1CompanyAddress: yup.string().required('Address is a required field.'),
    r1CompanyCity: yup.string().required('City is a required field.'),
    r1CompanyCountry: yup.string().required('Country is a required field.'),
    r1CompanyZipCode: yup.string().required('Zip code is a required field.'),
    r1VatNumber: yup.string().required('VAT number is a required field.'),
  })
  .defined();

export const CompanyInvoiceInformationPage = (): JSX.Element => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigator = useNavigator();
  const user = useUser();
  const updateCompanyInvoiceInformation =
    useUpdateMyCompanyInvoiceInformation();
  const methods = useForm<UpdateMeParams>({
    defaultValues: {
      r1CompanyName: user?.r1CompanyName || '',
      r1CompanyAddress: user?.r1CompanyAddress || '',
      r1CompanyCity: user?.r1CompanyCity || '',
      r1CompanyCountry: user?.r1CompanyCountry || '',
      r1CompanyZipCode: user?.r1CompanyZipCode || '',
      r1VatNumber: user?.r1VatNumber || '',
    },
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });

  const handleCompanyInvoiceInformationUpdate: SubmitHandler<
    UpdateMeParams
  > = async (data: UpdateMeParams) => {
    try {
      setIsLoading(true);
      await updateCompanyInvoiceInformation(data);
      showPrivateToast('Information updated');
      navigator.toAccount();
    } catch (err: any) {
      console.error('Something went wrong.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Layout.Content className={companyInvoiceInformationPageStyle}>
      <Button
        startIcon={<ChevronLeft />}
        variant={ButtonVariantEnum.TEXT}
        size={ButtonSizeEnum.LARGE}
        text={`account`}
        className={backButtonStyle}
        onClick={navigator.toPrevious}
      />
      <div className={companyInvoiceInformationPageHeaderStyle}>
        <h1>
          Company invoice
          <br />
          information
        </h1>
      </div>

      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(handleCompanyInvoiceInformationUpdate)}
        >
          <TextInput
            placeHolder={'Your company name'}
            name="r1CompanyName"
            markAsRequired={true}
          />
          <TextInput
            placeHolder={'Your company VAT number'}
            name="r1VatNumber"
            markAsRequired={true}
          />
          <TextInput
            placeHolder={'Your company address'}
            name="r1CompanyAddress"
            markAsRequired={true}
          />
          <TextInput
            placeHolder={'Your company city'}
            name="r1CompanyCity"
            markAsRequired={true}
          />
          <TextInput
            placeHolder={'Your company city zip code'}
            name="r1CompanyZipCode"
            markAsRequired={true}
          />
          <SelectInput
            placeHolder={'Your company country'}
            name="r1CompanyCountry"
            markAsRequired={true}
            options={[
              {
                value: SupportedCountries.CROATIA,
                label: 'Croatia',
              },
            ]}
          />
          <Button
            disabled={isLoading}
            variant={ButtonVariantEnum.FILLED}
            size={ButtonSizeEnum.LARGE}
            fullWidth={true}
            text="save"
            type={'submit'}
            loading={isLoading}
          />
        </form>
      </FormProvider>
    </Layout.Content>
  );
};

const companyInvoiceInformationPageStyle = css`
  padding-top: 0.625rem;
`;

const backButtonStyle = css`
  padding-top: 0rem;
`;

const companyInvoiceInformationPageHeaderStyle = css`
  h1 {
    ${h1Style}
    margin-bottom: 1.5rem;
  }
`;
