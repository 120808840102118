import axios, { AxiosResponse } from 'axios';
import {
  CheckEmailParams,
  GetLocationInputParams,
  EmailSignInParams,
  SignUpParams,
  GetPedestalInputParams,
  EmailVerificationParams,
  GetReceiptOverviewParams,
  LocationSearchParams,
  UpdateMeParams,
  UpdateMyPasswordParams,
  UpdateMyEmailParams,
  VerifyNewEmailParams,
  AddVehicleParams,
  UpdateVehicleParams,
  DeleteVehicleParams,
  CreatePaymentOrderBody,
  StartSessionParams,
  StopSessionParams,
  GetFilteredReceiptsParams,
  UsernameSignInParams,
  NewEmployeeParams,
  EditEmployeeParams,
  UpdateMyCompanyInvoiceInformationParams,
} from '../types/input-param-types';
import {
  Pedestal,
  User,
  Location,
  Token,
  Receipt,
  SessionGroup,
  PaymentOrder,
  SessionRequest,
} from '../types/server-types';
import { configuration } from '../utils/configuration';

export const axiosInstance = axios.create({
  baseURL: configuration.BACKEND_URL,
  withCredentials: true,
});

axiosInstance.interceptors.request.use((config: any) => {
  const token = window.localStorage.getItem('token')!;
  config.headers.Authorization = `Bearer ${token}`;
  return config;
});

class HTTP {
  checkIfEmailExists = async (params: CheckEmailParams) => {
    const { data } = await axiosInstance.post<
      CheckEmailParams,
      AxiosResponse<boolean>
    >(`/auth/check/email`, params);
    return data;
  };
  emailSignIn = async (params: EmailSignInParams) => {
    const { data } = await axiosInstance.post<
      EmailSignInParams,
      AxiosResponse<any>
    >(`/auth/signin/email`, params);
    return data;
  };
  usernameSignIn = async (params: UsernameSignInParams) => {
    const { data } = await axiosInstance.post<
      UsernameSignInParams,
      AxiosResponse<any>
    >(`/auth/signin/username`, params);
    return data;
  };
  getCurrentUser = async () => {
    const { data } = await axiosInstance.get<User>(`/users/me`);
    return data;
  };
  signUpUser = async (params: SignUpParams) => {
    const { data } = await axiosInstance.post<
      SignUpParams,
      AxiosResponse<User>
    >(`/auth/signup/email`, params);
    return data;
  };
  sendVerificationCode = async (params: EmailVerificationParams) => {
    const { data } = await axiosInstance.post<any, AxiosResponse<Token>>(
      `/auth/signup/email/verification`,
      params,
    );
    return data;
  };
  getLocation = async (params: GetLocationInputParams): Promise<Location> => {
    const { data } = await axiosInstance.get<Location>(
      `/locations/${params.id}`,
    );
    return data;
  };
  getLocations = async (params?: LocationSearchParams): Promise<Location[]> => {
    const { data } = await axiosInstance.get<Location[]>(`/locations`, {
      params,
    });
    return data;
  };
  getPedestal = async (params: GetPedestalInputParams): Promise<Pedestal> => {
    const { data } = await axiosInstance.get<Pedestal>(
      `/pedestal/${params.identifier}`,
    );
    return data;
  };

  getReceipts = async (): Promise<Receipt[]> => {
    const { data } = await axiosInstance.get<Receipt[]>(`/receipts`);
    return data;
  };

  getReceipt = async (params: GetReceiptOverviewParams): Promise<Receipt> => {
    const { data } = await axiosInstance.get<Receipt>(`/receipts/${params.id}`);
    return data;
  };

  getPaymentOrders = async (): Promise<PaymentOrder[]> => {
    const { data } = await axiosInstance.get<PaymentOrder[]>(`/payment/orders`);
    return data;
  };

  getPaymentOrder = async (id: number): Promise<PaymentOrder> => {
    const { data } = await axiosInstance.get<PaymentOrder>(
      `/payment/order/${id}`,
    );
    return data;
  };

  getInvoicePdf = async (orderId: number) => {
    return await axiosInstance.get(`/payment/invoice/${orderId}`, {
      responseType: 'blob',
    });
  };

  updateMe = async (params: UpdateMeParams): Promise<User> => {
    const { data } = await axiosInstance.put(`/users/me`, params);
    return data as User;
  };

  updateMyPassword = async (params: UpdateMyPasswordParams) => {
    return await axiosInstance.put(`/auth/change-password`, params);
  };

  updateMyEmail = async (params: UpdateMyEmailParams) => {
    return await axiosInstance.put(`/auth/change-email`, params);
  };

  updateMyUsername = async (params: UpdateMyEmailParams) => {
    return await axiosInstance.put(`/auth/change-username`, params);
  };

  updateMyCompanyInvoiceInformation = async (
    params: UpdateMyCompanyInvoiceInformationParams,
  ) => {
    return await axiosInstance.put(
      `/users/change-company-invoice-information`,
      params,
    );
  };

  verifyNewEmail = async (params: VerifyNewEmailParams) => {
    return await axiosInstance.put(`/auth/change-email/verification`, params);
  };

  addVehicle = async (params: AddVehicleParams) => {
    return await axiosInstance.post<AddVehicleParams, AxiosResponse<any>>(
      `/vehicle`,
      params,
    );
  };

  updateVehicle = async (params: UpdateVehicleParams) => {
    return await axiosInstance.patch<UpdateVehicleParams, AxiosResponse<any>>(
      `/vehicle/${params.id}`,
      params,
    );
  };

  deleteVehicle = async (params: DeleteVehicleParams) => {
    return await axiosInstance.delete(`/vehicle/delete/${params.id}`);
  };

  getEmployees = async () => {
    const { data } = await axiosInstance.get(`/users/employee/all`);
    return data;
  };

  addEmployee = async (params: NewEmployeeParams) => {
    const { data } = await axiosInstance.post(`/users/employee/create`, params);
    return data;
  };

  editEmployee = async (params: EditEmployeeParams) => {
    const { data } = await axiosInstance.patch(`/users/employee/edit`, params);
    return data;
  };

  deleteEmployee = async (id: number) => {
    const { data } = await axiosInstance.delete(`/users/employee/delete/${id}`);
    return data;
  };

  createPaymentOrder = async (
    body: CreatePaymentOrderBody,
  ): Promise<PaymentOrder> => {
    const { data }: { data: PaymentOrder } = await axiosInstance.post(
      `/payment/create-order`,
      body,
    );
    return data;
  };

  createAccount = async (locationId: number) => {
    const { data } = await axiosInstance.post(`/accounts/create`, {
      locationId,
    });
    return data;
  };

  getSessionGroup = async (id: string): Promise<SessionGroup> => {
    const { data } = await axiosInstance.get(`/session-group/${id}`);
    return data;
  };

  startSession = async (
    params: StartSessionParams,
  ): Promise<SessionRequest> => {
    const { data } = await axiosInstance.post<
      any,
      AxiosResponse<SessionRequest>
    >(`/session/request-session-start`, params);
    return data;
  };

  stopSession = async (params: StopSessionParams): Promise<SessionRequest> => {
    const { data } = await axiosInstance.post<
      any,
      AxiosResponse<SessionRequest>
    >(`/session/request-session-stop`, params);
    return data;
  };

  getSessionRequest = async (requestId: number | undefined) => {
    const { data } = await axiosInstance.get<
      any,
      AxiosResponse<SessionRequest>
    >(`session/request/${requestId}`);
    return data;
  };

  getActiveSessionGroup = async (accountId: number | undefined) => {
    const { data } = await axiosInstance.get<any, AxiosResponse<SessionGroup>>(
      `session/active-session-group/${accountId}`,
    );
    return data;
  };

  endAllSessions = async (id?: number): Promise<void> => {
    await axiosInstance.post(`/session/end-all-in-group/${id}`);
  };

  forgotPassword = async (data: { email: string }) => {
    const response = await axiosInstance.post(`/auth/forgot-password`, {
      ...data,
      platform: 'CLIENT',
    });
    return response.data;
  };
  resetPassword = async (data: {
    password: string;
    passwordResetToken: string;
  }) => {
    const response = await axiosInstance.post(`/auth/reset-password`, data);
    return response.data;
  };
}

export const http = new HTTP();
