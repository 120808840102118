import { useQuery, UseQueryResult } from 'react-query';
import { PaymentOrder } from '../../types/server-types';
import { http } from '../http';
import { CacheKeys } from './cache-configuration';

export const usePaymentOrders = (): UseQueryResult<PaymentOrder[]> => {
  return useQuery(CacheKeys.PAYMENT_ORDERS, () => http.getPaymentOrders());
};

export const usePaymentOrder = (id: string): UseQueryResult<PaymentOrder> => {
  return useQuery(CacheKeys.PAYMENT_ORDER, () =>
    http.getPaymentOrder(parseInt(id)),
  );
};
