import { css } from '@emotion/css';
import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';
import {
  Button,
  ButtonSizeEnum,
  ButtonVariantEnum,
} from '../../../shared/components/Button';
import { Info } from '../../../shared/components/Info';
import { Layout } from '../../../shared/components/layout/Layout';
import { Loader } from '../../../shared/components/Loader';
import { Colors } from '../../../shared/styles/colors';
import { h3Style } from '../../../shared/styles/text';
import { ChevronLeft } from '../../../shared/assets/icons';
import { useNavigator } from '../../../routing/navigator';
import { convertEURToHRK } from '../../../shared/utils/currency-conversion';
import { usePaymentOrder } from '../../../shared/services/api/order';
import { http } from '../../../shared/services/http';

export const PaymentOrderDetailsPage = (): JSX.Element => {
  const { orderId } = useParams<{ orderId: string }>();
  const {
    data: order,
    isError,
    isLoading,
    isRefetching,
  } = usePaymentOrder(orderId ?? '0');

  const navigator = useNavigator();

  if (isLoading || isRefetching) {
    return <Loader />;
  }
  if (isError || !order) {
    return <p>Order does not exist!</p>;
  }

  const downloadInvoicePdf = async () => {
    try {
      const response = await http.getInvoicePdf(order.id);
      const blob = response.data;

      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');

      link.href = url;
      link.setAttribute('download', `invoice_${orderId}.pdf`);

      document.body.appendChild(link);

      link.click();

      link.remove();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading the invoice:', error);
    }
  };

  return (
    <Layout.Content className={historyOverviewContainerStyle}>
      <Button
        startIcon={<ChevronLeft />}
        variant={ButtonVariantEnum.TEXT}
        size={ButtonSizeEnum.LARGE}
        text={`back`}
        className={backButtonStyle}
        onClick={navigator.toPrevious}
      />
      <div>
        <h1>Overview</h1>
      </div>
      <div className={infoGroupStyle}>
        <Info
          label="Date"
          value={dayjs(
            order.invoiceDate ? order.invoiceDate : order.createdAt,
          ).format('DD.MM.YYYY. HH:mm')}
          className={infoTotalStyle}
        />
        <Info
          label="Amount"
          value={`${parseFloat(order.amount).toFixed(2)} EUR (${convertEURToHRK(
            parseFloat(order.amount),
          )} HRK)`}
          className={infoTotalStyle}
        />
        <Button
          size={ButtonSizeEnum.MEDIUM}
          variant={ButtonVariantEnum.FILLED}
          text={'Download invoice PDF'}
          onClick={() => downloadInvoicePdf()}
          className={pdfButtonStyle}
        />
      </div>
    </Layout.Content>
  );
};

const backButtonStyle = css`
  padding-top: 0rem;
  align-self: flex-start;
`;

const historyOverviewContainerStyle = css`
  display: flex;
  flex-direction: column;
  padding-top: 0.75rem;
`;

const infoGroupStyle = css`
  display: flex;
  flex-wrap: wrap;
  column-gap: 0.25rem;
  width: 100%;
  margin-bottom: 1.5rem;
  gap: 1rem;
`;

const infoTotalStyle = css`
  > span {
  }
  > div > p {
    ${h3Style}
    color: ${Colors.CAPRI_BLUE};
  }
`;

const pdfButtonStyle = css`
  margin-top: 1rem;
`;
