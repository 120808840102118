import { css } from '@emotion/css';
import { Layout } from '../../shared/components/layout/Layout';
import { HistoryLoadingState } from '../../shared/components/loading-states/HistoryLoadingState';
import { NoActivityYet } from '../../shared/components/NoActivityYet';
import { useUser } from '../../shared/providers/user-provider';
import { useReceipts } from '../../shared/services/api/receipt';
import { Colors } from '../../shared/styles/colors';
import { h1Style, valueStyle } from '../../shared/styles/text';
import { ReceiptsList } from './ReceiptsList';
import { NoPermission } from '../../shared/components/NoPermission';
import { usePaymentOrders } from '../../shared/services/api/order';

export const HistoryPage = (): JSX.Element => {
  const user = useUser();
  const {
    data: receipts,
    isLoading: isLoadingReceipts,
    isRefetching: isRefetchingReceipts,
    isError: isErrorReceipts,
  } = useReceipts();

  const {
    data: paymentOrders,
    isLoading: isLoadingPaymentOrders,
    isRefetching: isRefetchingPaymentOrders,
    isError: isErrorPaymentOrders,
  } = usePaymentOrders();

  if (isErrorReceipts || isErrorPaymentOrders) {
    return <p>Error</p>;
  }
  if (!user) {
    return <p>User not found</p>;
  }

  return (
    <Layout.Content className={historyPageStyle}>
      <div className={headerContainerStyle}>
        <h1>History</h1>
      </div>
      {user.charter ? (
        <NoPermission />
      ) : isLoadingReceipts ||
        isRefetchingReceipts ||
        isLoadingPaymentOrders ||
        isRefetchingPaymentOrders ? (
        <HistoryLoadingState />
      ) : receipts &&
        paymentOrders &&
        (receipts.length > 0 || paymentOrders.length > 0) ? (
        <ReceiptsList receipts={receipts} paymentOrders={paymentOrders} />
      ) : (
        <NoActivityYet />
      )}
    </Layout.Content>
  );
};

const historyPageStyle = css`
  padding-top: 0.75rem;
  padding-bottom: 8rem;
`;

const headerContainerStyle = css`
  display: flex;
  justify-content: space-between;
  > h1 {
    ${h1Style}
  }
`;

export const historyListStyle = css`
  ${valueStyle}
  list-style: none;
  margin-top: 2rem;
`;

export const listItem = css`
  padding: 0.5rem 0px;
  cursor: pointer;
`;

export const listItemContainerStyle = css`
  display: flex;
  justify-content: space-between;
`;

export const listItemTopContainerStyle = css`
  margin-bottom: 0.25rem;
`;

export const locationNameStyle = css`
  color: ${Colors.WINDOW_GRAY};
`;

export const paymentStatusStyle = css`
  color: ${Colors.SOURCE_AVAILABLE};
  text-align: right;
`;
export const pendingPaymentStyle = css`
  color: ${Colors.CAPRI_BLUE};
  text-align: right;
`;
