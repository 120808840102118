import { css } from '@emotion/css';
import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';
import {
  Button,
  ButtonSizeEnum,
  ButtonVariantEnum,
} from '../../../shared/components/Button';
import { Info } from '../../../shared/components/Info';
import InfoGroup from '../../../shared/components/InfoGroup';
import { Layout } from '../../../shared/components/layout/Layout';
import { Loader } from '../../../shared/components/Loader';
import { useReceipt } from '../../../shared/services/api/receipt';
import { Colors } from '../../../shared/styles/colors';
import { h3Style, valueStyle } from '../../../shared/styles/text';
import {
  AccountTypeEnum,
  PaymentTypeEnum,
  ReceiptStatusEnum,
} from '../../../shared/types/enums';
import { getTimeDiffFormated } from '../../../shared/utils/timeDiffFormated';
import { PaymentInfoTextCharter } from '../PaymentInfoText';
import { ReceiptItemOverviewCard } from './ReceiptItemOverviewCard';
import { uniq } from 'lodash';
import { ChevronLeft } from '../../../shared/assets/icons';
import { useNavigator } from '../../../routing/navigator';
import { useMemo } from 'react';
import { convertEURToHRK } from '../../../shared/utils/currency-conversion';
import { ReceiptItem } from '../../../shared/types/server-types';

export const ReceiptDetailsPage = (): JSX.Element => {
  const { receiptId } = useParams<{ receiptId: string }>();
  const {
    data: receipt,
    isError,
    isLoading,
    isRefetching,
  } = useReceipt({ id: receiptId! });

  const navigator = useNavigator();

  const total = useMemo(() => {
    if (receipt && receipt.items) {
      return receipt.items.reduce(
        (sum: number, item: any) => item.consumption * item.tariff + sum,
        0,
      );
    }
    return 0;
  }, [receipt]);

  if (isLoading || isRefetching) {
    return <Loader />;
  }
  if (isError || !receipt) {
    return <p>Receipt does not exist!</p>;
  }

  const formatedDuration = getTimeDiffFormated(
    receipt.periodStartDate,
    receipt.periodEndDate,
  );
  const onlyOneVehicle =
    uniq(receipt.items.map((item: ReceiptItem) => item.vehicleRegistration))
      .length === 1;

  const infoGroupElements = [
    {
      label: 'start date',
      value: dayjs(receipt.items[0].startDate).format('DD.MM.YYYY. HH:mm'),
    },
    {
      label: 'end date',
      value: dayjs(receipt.items[0].endDate).format('DD.MM.YYYY. HH:mm'),
    },
    { label: 'location', value: receipt?.account.location.name },
  ];

  const receiptInfoGroupElements = [
    { label: 'transaction identifier', value: receipt.transactionId },
  ];

  return (
    <Layout.Content className={historyOverviewContainerStyle}>
      <Button
        startIcon={<ChevronLeft />}
        variant={ButtonVariantEnum.TEXT}
        size={ButtonSizeEnum.LARGE}
        text={`back`}
        className={backButtonStyle}
        onClick={navigator.toPrevious}
      />
      <div>
        <h1>Overview</h1>
      </div>
      <InfoGroup elements={receiptInfoGroupElements} />
      <InfoGroup
        elements={
          onlyOneVehicle
            ? [
                ...infoGroupElements,
                {
                  label: 'vehicle',
                  value: receipt.items[0].vehicleRegistration,
                },
              ]
            : infoGroupElements
        }
        className={mainInfoGroupStyle}
      />
      <div className={infoGroupStyle}>
        <Info
          label="Duration"
          value={`${formatedDuration}`}
          className={infoTotalStyle}
        />
        <Info
          label="Total"
          value={`${total.toFixed(2)} EUR (${convertEURToHRK(total)} HRK)`}
          className={infoTotalStyle}
        />
      </div>
      {receipt.paymentType === PaymentTypeEnum.POSTPAID &&
        (receipt.status !== ReceiptStatusEnum.PAID ? (
          <>
            <div className={paymentStatusStyle}>
              <div className={isUnpaidLabelStyle}>Unpaid</div>
            </div>
            <PaymentInfoTextCharter />
          </>
        ) : (
          <div className={paymentStatusStyle}>
            <div className={isPaidLabelStyle}>Paid</div>
            <span>{dayjs(receipt.paidAt).format('DD.MM.YYYY HH:mm')}</span>
          </div>
        ))}
      {(receipt.account.type === AccountTypeEnum.CHARTER ||
        receipt.paymentType === PaymentTypeEnum.PERIODICAL) &&
        (receipt.status !== ReceiptStatusEnum.PAID ? (
          <>
            <div className={paymentStatusStyle}>
              <div className={isUnpaidLabelStyle}>Unpaid</div>
            </div>
            <PaymentInfoTextCharter />
          </>
        ) : (
          <div className={paymentStatusStyle}>
            <div className={isPaidLabelStyle}>Paid</div>
            <span>{dayjs(receipt.paidAt).format('DD.MM.YYYY. HH:mm')}</span>
          </div>
        ))}
      {receipt.items.map((item: ReceiptItem, index: number) => {
        return (
          <ReceiptItemOverviewCard
            key={index}
            item={item}
            last={index === receipt.items.length - 1}
            showVehicle={!onlyOneVehicle}
          />
        );
      })}
    </Layout.Content>
  );
};

const backButtonStyle = css`
  padding-top: 0rem;
  align-self: flex-start;
`;

const historyOverviewContainerStyle = css`
  display: flex;
  flex-direction: column;
  padding-top: 0.75rem;
`;

const mainInfoGroupStyle = css`
  margin-bottom: 0px;
`;

const infoGroupStyle = css`
  display: flex;
  flex-wrap: wrap;
  column-gap: 0.25rem;
  width: 100%;
  margin-bottom: 1.5rem;
`;

const infoTotalStyle = css`
  > span {
  }
  > div > p {
    ${h3Style}
    color: ${Colors.CAPRI_BLUE};
  }
`;

const paymentStatusStyle = css`
  ${valueStyle};
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
`;

const isPaidLabelStyle = css`
  padding: 0.75rem 0.5rem;
  border: 2px solid ${Colors.SOURCE_AVAILABLE};
  margin-right: 0.75rem;
`;

const isUnpaidLabelStyle = css`
  padding: 0.75rem 0.5rem;
  border: 2px solid ${Colors.ERROR};
`;
