import { cx } from '@emotion/css';
import dayjs from 'dayjs';

import { useNavigator } from '../../routing/navigator';
import { PaymentOrder, Receipt } from '../../shared/types/server-types';
import { PaymentTypeEnum, ReceiptStatusEnum } from '../../shared/types/enums';

import {
  historyListStyle,
  listItem,
  listItemContainerStyle,
  listItemTopContainerStyle,
  locationNameStyle,
  paymentStatusStyle,
  pendingPaymentStyle,
} from './HistoryPage';

import { Divider, DividerVariantEnum } from '../../shared/components/Divider';
import { convertEURToHRK } from '../../shared/utils/currency-conversion';

interface ReceiptsProps {
  receipts: Receipt[];
  paymentOrders: PaymentOrder[];
}

export const ReceiptsList = (props: ReceiptsProps): JSX.Element => {
  const { receipts, paymentOrders } = props;
  const navigator = useNavigator();

  const allReceipts = [
    ...receipts.map((r) => ({ ...r, class: 'Receipt' as const })),
    ...paymentOrders.map((po) => ({ ...po, class: 'PaymentOrder' as const })),
  ];

  const sortedReceipts = allReceipts.sort(
    (r1, r2) =>
      new Date(r2.createdAt).getTime() - new Date(r1.createdAt).getTime(),
  );

  return (
    <ul className={historyListStyle}>
      {sortedReceipts.map((receipt, index) => {
        if (receipt.class === 'Receipt') {
          const total = receipt.items.reduce(
            (sum, item) => item.consumption * item.tariff + sum,
            0,
          );

          return (
            <li
              key={index}
              onClick={() => navigator.toReceiptDetails(receipt.id)}
            >
              <div className={listItem}>
                <div
                  className={cx(
                    listItemContainerStyle,
                    listItemTopContainerStyle,
                  )}
                >
                  <span>
                    {receipt.paymentType === PaymentTypeEnum.PERIODICAL
                      ? `${dayjs(receipt.periodStartDate).format(
                          'DD.MM.YYYY.',
                        )} -
                      ${dayjs(receipt.periodEndDate).format('DD.MM.YYYY.')}`
                      : dayjs(receipt.periodEndDate).format('DD.MM.YYYY.')}
                  </span>
                  <span>
                    {total.toFixed(2)} EUR (${convertEURToHRK(total)} HRK)
                  </span>
                </div>
                <div className={listItemContainerStyle}>
                  <span className={locationNameStyle}>
                    {receipt.account.location.name}
                  </span>
                  {receipt.status === ReceiptStatusEnum.PAID ? (
                    <span className={paymentStatusStyle}>Paid</span>
                  ) : (
                    <span className={pendingPaymentStyle}>Pending payment</span>
                  )}
                </div>
              </div>
              <Divider variant={DividerVariantEnum.LIST_DIVIDER} />
            </li>
          );
        } else {
          return (
            <li
              key={index}
              onClick={() => navigator.toPaymentOrderDetails(receipt.id)}
            >
              <div className={listItem}>
                <div
                  className={cx(
                    listItemContainerStyle,
                    listItemTopContainerStyle,
                  )}
                >
                  <span>{dayjs(receipt.createdAt).format('DD.MM.YYYY.')}</span>
                  <span>
                    + {parseFloat(receipt.amount).toFixed(2)} EUR ($
                    {convertEURToHRK(parseFloat(receipt.amount))} HRK)
                  </span>
                </div>
              </div>
              <div className={listItemContainerStyle}>
                <span className={locationNameStyle}>
                  {receipt.shopId === 'PREPAID' ? 'PREPAID' : 'CARD'}
                </span>
              </div>
              <Divider variant={DividerVariantEnum.LIST_DIVIDER} />
            </li>
          );
        }
      })}
    </ul>
  );
};
